/**
* jPList - jQuery Data Grid Controls 0.0.21 - http://jplist.com 
* Copyright 2015 jPList Software
*/
(function(){jQuery.fn.jplist.ui.controls.DatePickerRangeFilterDTO=function(d,g,h,e){d={path:d,format:g,filterType:"dateRange",prev_year:"",prev_month:"",prev_day:"",next_year:"",next_month:"",next_day:""};h&&(d.prev_year=h.getFullYear(),d.prev_month=h.getMonth(),d.prev_day=h.getDate());e&&(d.next_year=e.getFullYear(),d.next_month=e.getMonth(),d.next_day=e.getDate());return d}})();(function(){var d=function(b){var a={},c,f;c=b.$control.find('[data-type="prev"]');f=b.$control.find('[data-type="next"]');b.$control.data("jplist-datepicker-range-prev",c);b.$control.data("jplist-datepicker-range-next",f);c.off("change").change(function(){""===jQuery.trim(jQuery(this).val())&&(b.history.addStatus(g(b,!1)),b.observer.trigger(b.observer.events.unknownStatusesChanged,[!1]))});f.off("change").change(function(){""===jQuery.trim(jQuery(this).val())&&(b.history.addStatus(g(b,!1)),b.observer.trigger(b.observer.events.unknownStatusesChanged,
[!1]))});a.onSelect=function(a,c){b.history.addStatus(g(b,!1));b.observer.trigger(b.observer.events.unknownStatusesChanged,[!1])};b.params.datepickerFunc(c,a);b.params.datepickerFunc(f,a);(a=c.attr("value"))&&c.datepicker("setDate",a);(c=f.attr("value"))&&f.datepicker("setDate",c)},g=function(b,a){var c=null,f=c=null,d=null,e;e=b.$control.attr("data-path").toString();c=b.$control.attr("data-datetime-format").toString();f=b.$control.data("jplist-datepicker-range-prev");d=b.$control.data("jplist-datepicker-range-next");
f=f.datepicker("getDate");d=d.datepicker("getDate");c=new jQuery.fn.jplist.ui.controls.DatePickerRangeFilterDTO(e,c,f,d);return c=new jQuery.fn.jplist.app.dto.StatusDTO(b.name,b.action,b.type,c,b.inStorage,b.inAnimation,b.isAnimateToTop,b.inDeepLinking)},h=function(b,a,c){b=c.split(b.options.delimiter2);3===b.length&&(a.data.prev_year=b[0],a.data.prev_month=b[1],a.data.prev_day=b[2])},e=function(b,a,c){b=c.split(b.options.delimiter2);3===b.length&&(a.data.next_year=b[0],a.data.next_month=b[1],a.data.next_day=
b[2])},a=function(b){var a=b.$control.attr("data-datepicker-func");jQuery.isFunction(jQuery.fn.jplist.settings[a])&&(b.params.datepickerFunc=jQuery.fn.jplist.settings[a])},c=function(b){b.params={datepickerFunc:function(){}};a(b);d(b);return jQuery.extend(this,b)};c.prototype.getStatus=function(a){return g(this,a)};c.prototype.getDeepLink=function(){var a="",c,d,f;this.inDeepLinking&&(c=g(this,!1),c.data&&(d=jQuery.isNumeric(c.data.prev_year)&&jQuery.isNumeric(c.data.prev_month)&&jQuery.isNumeric(c.data.prev_day),
f=jQuery.isNumeric(c.data.next_year)&&jQuery.isNumeric(c.data.next_month)&&jQuery.isNumeric(c.data.next_day),d||f))&&(a+=this.name+this.options.delimiter0,d&&(a+="prev"),f&&(d&&(a+=this.options.delimiter2),a+="next"),a+="=",d&&(a+=c.data.prev_year+this.options.delimiter2+c.data.prev_month+this.options.delimiter2+c.data.prev_day),f&&(d&&(a+=this.options.delimiter3),a+=c.data.next_year+this.options.delimiter2+c.data.next_month+this.options.delimiter2+c.data.next_day));return a};c.prototype.getStatusByDeepLink=
function(a,c){var d=null,f;if(this.inDeepLinking&&(d=g(this,!0),delete d.data.next_year,delete d.data.next_month,delete d.data.next_day,delete d.data.prev_year,delete d.data.prev_month,delete d.data.prev_day,d.data))switch(a){case "prev":h(this,d,c);break;case "next":e(this,d,c);break;case "prev~next":f=c.split(this.options.delimiter3),2===f.length&&(h(this,d,f[0]),e(this,d,f[1]))}return d};c.prototype.getPaths=function(a){var c;if(c=this.$control.attr("data-path").toString())c=new jQuery.fn.jplist.domain.dom.models.DataItemMemberPathModel(c,
"datetime"),a.push(c)};c.prototype.setStatus=function(a,c){var d,f,e;f=this.$control.data("jplist-datepicker-range-prev");e=this.$control.data("jplist-datepicker-range-next");jQuery.isNumeric(a.data.prev_year)&&jQuery.isNumeric(a.data.prev_month)&&jQuery.isNumeric(a.data.prev_day)?(d=new Date(a.data.prev_year,a.data.prev_month,a.data.prev_day),f.datepicker("setDate",d)):f.val("");jQuery.isNumeric(a.data.next_year)&&jQuery.isNumeric(a.data.next_month)&&jQuery.isNumeric(a.data.next_day)?(d=new Date(a.data.next_year,
a.data.next_month,a.data.next_day),e.datepicker("setDate",d)):e.val("")};jQuery.fn.jplist.ui.controls.DatePickerRangeFilter=function(a){return new c(a)};jQuery.fn.jplist.controlTypes["date-picker-range-filter"]={className:"DatePickerRangeFilter",options:{}}})();(function(){jQuery.fn.jplist.ui.controls.DatePickerFilterDTO=function(d,g,h){d={path:d,format:g,filterType:"date",year:"",month:"",day:""};h&&(d.year=h.getFullYear(),d.month=h.getMonth(),d.day=h.getDate());return d}})();(function(){var d=function(a){var c={};a.$control.off("change").on("change",function(){var b;""===jQuery.trim(jQuery(this).val())&&(b=g(a,!1),a.history.addStatus(b),a.observer.trigger(a.observer.events.statusChanged,[b]))});c.onSelect=function(b,c){a.history.addStatus(g(a,!1));a.observer.trigger(a.observer.events.statusChanged,[g(a,!1)])};a.params.datepickerFunc(a.$control,c)},g=function(a,c){var b=null,b=b=null;c||(b=a.$control.datepicker("getDate"));b=new jQuery.fn.jplist.ui.controls.DatePickerFilterDTO(a.params.dataPath,
a.params.dateTimeFormat,b);return b=new jQuery.fn.jplist.app.dto.StatusDTO(a.name,a.action,a.type,b,a.inStorage,a.inAnimation,a.isAnimateToTop,a.inDeepLinking)},h=function(a){var c=a.$control.attr("data-datepicker-func");jQuery.isFunction(jQuery.fn.jplist.settings[c])&&(a.params.datepickerFunc=jQuery.fn.jplist.settings[c])},e=function(a){a.params={datepickerFunc:function(){},dataPath:a.$control.attr("data-path"),dateTimeFormat:a.$control.attr("data-datetime-format")};h(a);d(a);return jQuery.extend(this,
a)};e.prototype.getStatus=function(a){return g(this,a)};e.prototype.getDeepLink=function(){var a="",c;this.inDeepLinking&&(c=g(this,!1),c.data&&jQuery.isNumeric(c.data.year)&&jQuery.isNumeric(c.data.month)&&jQuery.isNumeric(c.data.day)&&(a+=this.name+this.options.delimiter0+"date="+c.data.year+this.options.delimiter2+c.data.month+this.options.delimiter2+c.data.day));return a};e.prototype.getStatusByDeepLink=function(a,c){var b=null,d;this.inDeepLinking&&(b=g(this,!0),b.data&&"date"===a&&(d=c.split(this.options.delimiter2),
3===d.length&&(b.data.year=d[0],b.data.month=d[1],b.data.day=d[2])));return b};e.prototype.getPaths=function(a){var c;this.params.dataPath&&(c=new jQuery.fn.jplist.domain.dom.models.DataItemMemberPathModel(this.params.dataPath,"datetime"),a.push(c))};e.prototype.setStatus=function(a,c){var b;jQuery.isNumeric(a.data.year)&&jQuery.isNumeric(a.data.month)&&jQuery.isNumeric(a.data.day)?(b=new Date(a.data.year,a.data.month,a.data.day),this.$control.datepicker("setDate",b)):this.$control.val("")};jQuery.fn.jplist.ui.controls.DatePickerFilter=
function(a){return new e(a)};jQuery.fn.jplist.controlTypes["date-picker-filter"]={className:"DatePickerFilter",options:{}}})();(function(){jQuery.fn.jplist.ui.controls.RangeSliderDTO=function(d,g,h,e,a){return{path:d,type:"number",filterType:"range",min:g,max:h,prev:e,next:a}}})();(function(){var d=function(a,c){var b=null,d,e,f,b=a.params.$uiSlider.slider("option","min");d=a.params.$uiSlider.slider("option","max");c?(e=a.params.defaultPrev,f=a.params.defaultNext):(e=a.params.$uiSlider.slider("values",0),f=a.params.$uiSlider.slider("values",1));b=new jQuery.fn.jplist.ui.controls.RangeSliderDTO(a.params.dataPath,b,d,e,f);return b=new jQuery.fn.jplist.app.dto.StatusDTO(a.name,a.action,a.type,b,a.inStorage,a.inAnimation,a.isAnimateToTop,a.inDeepLinking)},g=function(a){a.params.$uiSlider.on("slidechange",
function(c,b){a.history.addStatus(d(a,!1));a.observer.trigger(a.observer.events.unknownStatusesChanged,[!1])})},h=function(a){var c=a.$control.attr("data-slider-func"),b=a.$control.attr("data-setvalues-func");jQuery.isFunction(jQuery.fn.jplist.settings[c])&&(a.params.uiSliderFunc=jQuery.fn.jplist.settings[c]);jQuery.isFunction(jQuery.fn.jplist.settings[b])&&(a.params.uiSetValuesFunc=jQuery.fn.jplist.settings[b])},e=function(a){a.params={$uiSlider:a.$control.find('[data-type="ui-slider"]'),$prev:a.$control.find('[data-type="prev-value"]'),
$next:a.$control.find('[data-type="next-value"]'),uiSliderFunc:function(a,b,d){},uiSetValuesFunc:function(a,b,d){},controlOptions:a.controlOptions,dataPath:a.$control.attr("data-path")};h(a);a.params.uiSliderFunc(a.params.$uiSlider,a.params.$prev,a.params.$next);a.params.uiSetValuesFunc(a.params.$uiSlider,a.params.$prev,a.params.$next);a.params.defaultPrev=a.params.$uiSlider.slider("values",0);a.params.defaultNext=a.params.$uiSlider.slider("values",1);g(a);return jQuery.extend(this,a)};e.prototype.getStatus=
function(a){return d(this,a)};e.prototype.getDeepLink=function(){var a="",c;this.inDeepLinking&&(c=d(this,!1),c.data&&jQuery.isNumeric(c.data.prev)&&jQuery.isNumeric(c.data.next)&&(a=this.name+this.options.delimiter0+"prev"+this.options.delimiter2+"next="+c.data.prev+this.options.delimiter2+c.data.next));return a};e.prototype.getStatusByDeepLink=function(a,c){var b=null,e;this.inDeepLinking&&(b=d(this,!0),b.data&&a==="prev"+this.options.delimiter2+"next"&&(e=c.split(this.options.delimiter2),2===e.length&&
(b.data.prev=e[0],b.data.next=e[1])));return b};e.prototype.getPaths=function(a){var c;this.params.dataPath&&(c=new jQuery.fn.jplist.domain.dom.models.DataItemMemberPathModel(this.params.dataPath,"number"),a.push(c))};e.prototype.setStatus=function(a,c){var b,d;jQuery.isNumeric(a.data.prev)&&jQuery.isNumeric(a.data.next)&&(b=Number(a.data.prev),d=Number(a.data.next),isNaN(b)||isNaN(d)||(this.params.$uiSlider.slider("values",0)!=b&&this.params.$uiSlider.slider("values",0,b),this.params.$uiSlider.slider("values",
1)!=d&&this.params.$uiSlider.slider("values",1,d)));this.params.controlOptions&&this.params.uiSetValuesFunc(this.params.$uiSlider,this.params.$prev,this.params.$next)};jQuery.fn.jplist.ui.controls.RangeSlider=function(a){return new e(a)};jQuery.fn.jplist.controlTypes["range-slider"]={className:"RangeSlider",options:{}}})();
